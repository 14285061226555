import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import { Container, Row, Col, } from "react-bootstrap"
import dowDoc from "../../images/download-doc.png"
import felisw3detail from "../../images/Felis-W3.webp"
import felisw3Doc from '../../docs/Felis-W3.pdf'
class Felisw3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return <Layout>
      <SEO title="Felis-W3 | Tumble Washer Controller"
      description="Proalcs's semi-professional serial controller with screen for industrial washing machines" />
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Felis-W3 </h1>
        </div>
        <Row>
          <Col sm className='product-detail-head'>
            <div className={`product-detail-header-image´  ${this.state.isToggleOn ? 'product-detail-header-image' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={felisw3detail} alt="felis-w3-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Felis-W3</h1>
            </div>
            <h2>Tumble Washer Controller</h2>
            <p>It is a professional serial controller with 7 ”LCD (800x480 RGB) screen for industrial washing machines,
  designed for heavy industrial conditions. Membrane keypad is easy to use. Resistant to vibration and dust. It
  has 100 programming capacity and 9 working stages. The design of the control panel is customized
according to the needs and designed in accordance with the brand according to customer demands.</p>
          </Col>
        </Row>
        <Row>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
                <a  target="_blank" rel="noopener noreferrer"  href={felisw3Doc} >  <img className='product-detail-doc' src={dowDoc} alt="proalcs felix w3 document" />
                  <h1>Felis-W3</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Specifications</h1>
        </div>
        <Row className='lms-inside-content'>
          <Col>
            <li>  Fast commissioning with direct connection.<br></br></li>
            <li>   On board level sensor allows water level control (No need external sensor)<br></br></li>
            <li>   User program support, pictures and explanations, troubleshooting, diagnostic control and flexible parameter list.<br></br></li>
            <li>   Backup and restore via USB (programs, parameters, database, version update, etc.)<br></br></li>
            <li>   Quick access to frequently used programs with 5 Memory keys.<br></br></li>
            <li>   Suitable for heating / steam heating.<br></br></li>
            <li>   Suitable for coin operated.<br></br></li>
            <li>   Support for tilting, Helms protocol, wet cleaning, washing hold support.<br></br></li>
            <li>   AC-Drive and balance detection control via Modbus RTU. (Optional)<br></br></li>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Features</h1>
        </div>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Inputs / Outputs</h1>
        </div>

        <Row className="product-table-row">
          <Col>
            <p>Analog Input</p>
          </Col>
          <Col>
            <p>
              Onboard Level Sensor (Hydrostatic 0-100 cm)
          <br />
              Temperature Sensor Input (-10~100 °C)
        </p>
          </Col>
          <Col className="lastp">
            <p>
              1<br />1
        </p>
          </Col>
        </Row>

        <Row className="product-table-row">
          <Col>
            <p>Analog Output</p>
          </Col>
          <Col>
            <p>AC Drive Control 0~10V DC </p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Input</p>
          </Col>
          <Col>
            <p>5-24V DC</p>
          </Col>
          <Col className="lastp">
            <p>8</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Counter Input</p>
          </Col>
          <Col>
            <p>5-24V DC 1 KHz </p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Output</p>
          </Col>
          <Col>
            <p>24V DC 1A Relay NO</p>
          </Col>
          <Col className="lastp">
            <p>16</p>
          </Col>
        </Row>

        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Programming</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Program Count</p>
          </Col>
          <Col>
            <p>
              16 Default Programs <br />
              84 User Programs
        </p>
          </Col>
          <Col className="lastp">
            <p>100</p>
          </Col>
        </Row>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Technical Specifications</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Operating Voltage</p>
          </Col>
          <Col>
            <p>24 VDC </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Power Consumption</p>
          </Col>
          <Col>
            <p>15 Watt</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Temperature</p>
          </Col>
          <Col>
            <p>0~60 °C</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Humidty</p>
          </Col>
          <Col>
            <p>%20~%95 (Non condensing)</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Processor</p>
          </Col>
          <Col>
            <p>ARM 11 CPU Core </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Screen Size </p>
          </Col>
          <Col>
            <p>7” RGB TFT </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Screen Resolution </p>
          </Col>
          <Col>
            <p>800x480</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>USB 2.0 (Rear Face) </p>
          </Col>
          <Col>
            <p>1</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>RS485 Communication port </p>
          </Col>
          <Col>
            <p>1</p>
          </Col>
          <Col className="lastp" />
        </Row>




      </Container >
    </Layout >
  }
};
export default Felisw3
